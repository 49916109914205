































import Vue from "vue";

export default Vue.extend({
  name: "Totp",
  data() {
    return {
      totp: '',
    };
  },
  mounted() {
    if (!this.$route.params.email || !this.$route.params.password)
      this.$router.push({
        path: '/login',
        query: {
          redirect: this.$route.params.redirect
        },
      });
  },
  methods: {
    login() {
      this.axios.post('auth/login', {
        email: this.$route.params.email,
        password: this.$route.params.password,
        token: this.totp,
      }, { withCredentials: true }).then(() => {
        this.axios.get('auth/user', { withCredentials: true })
          .then((res) => {
            const info = res.data;
            this.$store.state.authInfo.id = info.id;
            this.$store.state.authInfo.email = info.email;
            this.$store.state.authInfo.preName = info.preName;
            this.$store.state.authInfo.lastName = info.lastName;
            this.$store.state.authInfo.permissions = info.permissions;
            this.$store.state.authInfo.totpEnabled = info.totpEnabled;
            this.$store.state.loggedIn = true;
            this.redirect();
          });
      });
    },
    redirect() {
      const redirect = this.$route.params.redirect?.toString();
      if (!redirect) return this.$router.push('/');
      switch (redirect) {
        case 'back':
          window.history.back();
          break;
        case 'close':
          window.close();
          break;
        default:
          this.$router.push(redirect);
          break;
      }
    },
  },
  watch: {
    totp() {
      if (this.totp.length >= 6) this.login();
    },
  },
});
